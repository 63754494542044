// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
import { getStorage } from "firebase/storage";
import { getFirestore } from "firebase/firestore";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCVYkTcPj1I2M4XyWs4BQdobFEgTJO9CYY",
  authDomain: "two-maids-photos.firebaseapp.com",
  projectId: "two-maids-photos",
  storageBucket: "two-maids-photos.appspot.com",
  messagingSenderId: "613992150633",
  appId: "1:613992150633:web:d0ab34058b6a0c0f0914f8"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);
export const db = getFirestore(app);