import { getDownloadURL, getMetadata, listAll, ref } from 'firebase/storage';
import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import 'react-clock/dist/Clock.css';
import { Blocks } from 'react-loader-spinner';
import './App.css';
import MaidBackground from './assets/images/maid_bg.jpeg';
import Logo from './assets/images/twomaidslogo.svg';
import Collage from './components/Collage';
import DigitalClock from './components/DigitalClock';
import DigitalClock1 from './components/DigitalClock1';
import Progress from './components/Progress';
import { storage } from './firebase';

function App() {
  const [imageUrls, setImageUrls] = useState([]);
  const [activeImages, setActiveImages] = useState([{ url: '' }]);
  const currentYear = new Date().getFullYear();
  const [loading, setLoading] = useState(true);
  const [collages, setCollages] = useState([]);
  const [doneFetching, setDoneFetching] = useState(false);
  const [reset, setReset] = useState(false);
  const [currentCollage, setCurrentCollage] = useState(0);

  const time = 30000;

  const imagesListRef = ref(storage, 'images/');

  const fetchData = async () => {
    const list = await listAll(imagesListRef);
    let urls = [];
    const imageData = list.items.map(async (itemRef) => {
      let image_name = '';
      let uploadedBy = '';
      let imageLocation = '';
      const url = await getDownloadURL(itemRef);
      urls.push(url);
      const metadata = await getMetadata(itemRef);
      const { customMetadata } = metadata;
      if (customMetadata) {
        image_name = customMetadata?.imageName ?? '';
        uploadedBy = customMetadata?.uploadedBy ?? '';
        imageLocation = customMetadata?.imageLocation ?? '';
      }
      return {
        url: url,
        name: image_name ?? '',
        uploadedBy: uploadedBy,
        imageLocation: imageLocation,
      };
    });
    console.log('imageData', imageData);
    return imageData;
  };

  useEffect(() => {
    // debugger;
    (async () => {
      let test = [];
      let result = await fetchData();
      for (let element of result) {
        let res = await element;
        console.log('res', res);
        test.push(res);
      }
      setImageUrls(test);
    })();
  }, []);

  useEffect(() => {
    for (let i = 0; i < imageUrls.length; i += 5) {
      setCollages((prev) => [...prev, imageUrls.slice(i, i + 5)]);
    }
  }, [imageUrls]);

  useEffect(() => {
    if (!collages.length) return;
    const interval = setInterval(() => {
      setCurrentCollage((currentCollage) => {
        if (currentCollage >= collages.length - 1) {
          return 0;
        }
        return (currentCollage += 1);
      });
    }, time);
  }, [collages]);

  return (
    <div className='App'>
      <img src='https://res.cloudinary.com/twomaidsengi/image/upload/v1670257150/hubsite-reskin/two-maids-logo-white.png' alt='logo' className='logo' />
      <img className='demo-bg' src='https://res.cloudinary.com/twomaidsengi/image/upload/v1723660112/map-dashboard/maid_bg.jpg' alt='' />
      <h2>Two Maids Photos</h2>
      <p
        style={{
          textAlign: 'center',
          fontSize: '15px',
          top: '12%',
          left: '50%',
          transform: 'translateX(-50%) translateY(-50%)',
          position: 'absolute',
        }}
      >
        Share your photos{' '}
        <a href='https://photogallery.gataware.com/upload'>here!</a>
      </p>

      <div>
        {collages.length &&
          collages.map((collage, index) => (
            <Collage
              collage={collage}
              time={time}
              index={index + 1}
              collagesLength={collages.length}
              active={currentCollage === index}
            />
          ))}
        <p
          style={{
            textAlign: 'center',
            fontSize: '22px',
            marginTop: '22px',
            color: '#fff',
            textShadow: ' 0 1px 2px #fff',
          }}
        >{`${currentCollage + 1}/${collages.length}`}</p>
      </div>

      {/* <Blocks
        visible={loading}
        height='80'
        width='80'
        ariaLabel='blocks-loading'
        wrapperStyle={{}}
        wrapperClass='blocks-wrapper'
      /> */}
      {/* <DigitalClock1 /> */}

      <span
        style={{ position: 'absolute', top: 30, right: 60, fontSize: '22px' }}
      ></span>
      <footer>
        <p>{currentYear} © Gataware Software Suite.</p>
        <p> Two Maids Franchising, LLC. </p>
        <p>All Rights Reserved. </p>
        <p> Made with &#10084; in Birmingham, Alabama.</p>
      </footer>
      {/* <Progress /> */}
    </div>
  );
}

export default App;
