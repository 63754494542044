import { AnimatePresence, motion } from 'framer-motion';
import { useEffect, useState } from 'react';

const Collage = ({ collage, active }) => {
  return (
    <AnimatePresence>
      <motion.div className='gallery' style={{ display: active ? '' : 'none' }}>
        {active &&
          collage.map((url, index) => {
            return (
              <motion.figure
                className={`gallery__item gallery__item--${index + 1}`}
                key={Math.random()}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
              >
                <img
                  className='gallery__img'
                  src={url.url}
                  alt={url.name ?? 'image'}
                  loading='lazy'
                />
                {url.name && (
                  <figcaption>{`${url.name} - ${url.uploadedBy}`}</figcaption>
                )}
              </motion.figure>
            );
          })}
      </motion.div>
    </AnimatePresence>
  );
};

export default Collage;
