import { ref, uploadBytes } from 'firebase/storage';
import heic2any from 'heic2any';
import { useState } from 'react';
import { Blocks } from 'react-loader-spinner';
import { toast } from 'react-toastify';
import { v4 } from 'uuid';
import './App.css';

import imageCompression from 'browser-image-compression';
import { storage } from './firebase';

function Upload() {
  const [imageUpload, setImageUpload] = useState(null);
  const [imageName, setImageName] = useState('');
  const [imageLocation, setImageLocation] = useState('');
  const [uploadedBy, setUploadedBy] = useState('');
  const [pictureDate, setPictureDate] = useState('');

  const [loading, setLoading] = useState(false);

  const options = {
    maxSizeMB: 1,
    maxWidthOrHeight: 1920,
    useWebWorker: true,
  };

  const uploadFile = async (event) => {
    event.preventDefault();

    var myCustomMetadata = {
      customMetadata: {
        imageName: imageName,
        imageLocation: imageLocation,
        uploadedBy: uploadedBy,
      },
    };
    for (let image of imageUpload) {
      setLoading(true);
      const imageRef = ref(storage, `images/${v4()}`);
      let conversionResult = null;
      if (image.name.includes('.HEIC')) {
        // get image as blob url
        let blobURL = URL.createObjectURL(image);

        // convert "fetch" the new blob url
        let blobRes = await fetch(blobURL);

        // convert response to blob
        let blob = await blobRes.blob();

        // convert to PNG - response is blob
        conversionResult = await heic2any({ blob, toType: 'image/png' });
      }

      let compressedFile = null;
      try {
        compressedFile = await imageCompression(
          conversionResult === null ? image : conversionResult,
          options
        );
      } catch (error) {
        console.log(error);
      }
      uploadBytes(
        imageRef,
        compressedFile ? compressedFile : image,
        myCustomMetadata
      )
        .then((snapshot) => {
          toast('Image uploaded successfully');
        })
        .catch((error) => {
          console.log(error);
          toast('An error occured');
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return (
    <form onSubmit={uploadFile} className='upload_container'>
      <h1>
        <strong>Two Maids</strong> Picture Frame Upload Form
      </h1>

      <div className='form-group'>
        <label for='title'>
          Title <span>Image Description - Max 30 Characters</span>
        </label>
        <input
          type='text'
          name='title'
          id='title'
          className='form-controll'
          value={imageName}
          maxlength="30"
          onChange={(event) => {
            setImageName(event.target.value);
          }}
        />
      </div>
      <div className='form-group'>
        <label for='caption'>
          Location <span>Store Location - Max 15 Characters</span>
        </label>
        <input
          type='text'
          name='caption'
          id='caption'
          className='form-controll'
          maxlength="15"
          value={imageLocation}
          onChange={(event) => {
            setImageLocation(event.target.value);
          }}
        />
      </div>
      <div className='form-group'>
        <label for='caption'>
          Picture Date <span>When was the picture taken</span>
        </label>
        <input
          type='date'
          name='caption'
          id='caption'
          className='form-controll'
          maxlength="15"
          value={pictureDate}
          onChange={(event) => {
            setPictureDate(event.target.value);
          }}
        />
      </div>
      <div className='form-group'>
        <label for='caption'>Who is uploading? <span>Max 15 Characters</span></label>
        <input
          type='text'
          name='caption'
          id='caption'
          className='form-controll'
          maxlength="15"
          value={uploadedBy}
          onChange={(event) => {
            setUploadedBy(event.target.value);
          }}
        />
      </div>
      <div className='form-group file-area'>
        <label for='images'>
          Images <span></span>
        </label>
        <input
          type='file'
          name='images'
          id='images'
          required='required'
          multiple='multiple'
          onChange={(event) => {
            setImageUpload(event.target.files);
          }}
        />

        <div className='file-dummy'>
          <div className='success'>
            Great, your files are selected. You can now submit.
          </div>
          <div className='default'>Please select some files</div>
        </div>
        <Blocks
          visible={loading}
          height='80'
          width='80'
          ariaLabel='blocks-loading'
          wrapperStyle={{}}
          wrapperClass='blocks-wrapper'
        />
      </div>

      <div className='form-group'>
        <button type='submit'>Upload images</button>
      </div>
    </form>
  );
}

export default Upload;
